import React from 'react'
import { Link } from 'react-router-dom'
import { GitHub } from 'react-feather'

import OpenDiscussions from './OpenDiscussions'
import ReadableUpdatedAt from '../components/ReadableUpdatedAt'

import './project-list-item.css'

const RepoLink = ({ url }) => (
  <a href={url} target="blank" className="project-list-item__repo">
    <span>
      <GitHub />
    </span>
  </a>
)

const ProjectListItem = ({ project }) => {
  const latestRun = project?.latestRun?.aggregate?.max?.created_at
  const unresolvedThreads = project?.unresolvedThreads?.aggregate?.count
  const testCases = project?.testCases?.aggregate?.count || 0
  const updatedAt = latestRun || project?.updated_at

  return (
    <li key={project.id} className="project-list-item">
      <div>
        <h3 className="project-list-item__title">
          <Link
            to={`/project/${project.id}`}
            data-testid={`project-name-${project.name}`}
            className="project-list-item__title-link"
          >
            {project.name}
          </Link>
        </h3>
        {project.description && <span className="muted-span">{project.description}</span>}
        <br />
        <span>
          Test cases:&nbsp;
          {testCases}
        </span>
        <br />
        {updatedAt && <ReadableUpdatedAt text="Updated" updatedAt={updatedAt} />}
      </div>
      <div className="project-list-item__info">
        <OpenDiscussions unresolvedThreads={unresolvedThreads} />
        <br />
        {project.repo_url && <RepoLink url={project.repo_url} />}
      </div>
    </li>
  )
}

export default ProjectListItem
