import React from 'react'
import { useParams, Link } from 'react-router-dom'
import { gql, useQuery } from '@apollo/client'
import { Edit, PlusCircle, MinusCircle } from 'react-feather'

import Image from '../components/Image'
import getReadableDate from '../utils/getReadableDate'

import './project-events.css'

const GET_CURRENT_PROJECT_EVENTS = gql`
  query GetProjectEvents($projectId: uuid!) {
    project: projects_by_pk(id: $projectId) {
      id
      runs: project_runs(order_by: {created_at: desc}) {
        id
        createdAt: created_at
        events: project_events {
          id
          file: project_file {
            id
            path
          }
          type
          new_text
          old_text
          image: project_image {
            id
            src
          }
        }
      }
    }
  }
`

const ChangeIcon = ({ type }) => {
  if (type === 'DELETE') return <MinusCircle size="20" className="red" />
  if (type === 'CHANGED') return <Edit size="20" className="orange" />
  if (type === 'ADD') return <PlusCircle size="20" className="green" />
  return null
}

const TextChange = ({ newText, oldText }) => ((
  <>
    <span>
      <i>
        {`"${newText}"`}
      </i>
    </span>
    {oldText && <span className="timeline-run__faded-text">{oldText}</span>}
  </>
))

const EventRun = ({ changes, createdAt }) => {
  const projectRunTime = getReadableDate(createdAt)

  if (!changes.length) {
    return (
      <div>
        <span>
          No test changes for commit run&nbsp;
          {projectRunTime}
        </span>
      </div>
    )
  }
  const timelineTableRows = changes.map((change) => {
    const changedContent = change.image?.src
      ? <Image src={change.image?.src} filePath={change.file?.path} />
      : <TextChange newText={change.new_text} oldText={change.old_text} />

    return (
      <li key={change.id}>
        <div className="timeline-run__change">
          <span><ChangeIcon type={change.type} /></span>
          <Link to={{
            pathname: window.location.pathName,
            search: `?file=${change.file?.path}`,
          }}
          >
            <span>{change.file?.path}</span>
          </Link>
          {change.image?.src ? 'Image:' : 'Test name:'}
          {changedContent}
        </div>
      </li>
    )
  })

  return (
    <div>
      <span>
        Changes from run&nbsp;
        {projectRunTime}
      </span>
      <ul className="timeline-run">
        {timelineTableRows}
      </ul>
    </div>
  )
}

const mapRunToEvents = ({ events, id, createdAt }) => <EventRun key={id} changes={events} createdAt={createdAt} />

const ProjectEvents = () => {
  const { projectId } = useParams()
  const {
    loading, error, data,
  } = useQuery(GET_CURRENT_PROJECT_EVENTS, { variables: { projectId } })

  if (error) return <span>{error.message}</span>
  if (loading) return <span>Loading</span>

  const projectEventRuns = data?.project?.runs?.map(mapRunToEvents)

  return (
    <section id="project-events" data-testid="project-events">
      <h3>Events</h3>
      {projectEventRuns}
    </section>
  )
}

export default ProjectEvents
