import Cookies from 'js-cookie'

const MS_PER_MINUTE = 60000
const MS_PER_DAY = 86400000

const getDatePlusMinutes = (minutes) => new Date(Date.now() + minutes * MS_PER_MINUTE)
const getDatePlusDays = (days) => new Date(Date.now() + days * MS_PER_DAY)

const setCookies = (authResponse) => {
  const jwtExpires = authResponse.jwt_token_expires_minutes || 15
  const refreshExpires = authResponse.refresh_expires_days || 60

  const inProd = window.location.protocol === 'https:'

  Cookies.set('jwt', authResponse.jwt_token, {
    secure: inProd,
    sameSite: 'strict',
    expires: getDatePlusMinutes(jwtExpires),
  })

  Cookies.set('refreshToken', authResponse.refresh, {
    secure: inProd,
    sameSite: 'strict',
    expires: getDatePlusDays(refreshExpires),
  })
}

export default setCookies
