import React, { useCallback } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { gql, useSubscription } from '@apollo/client'

import './project-dropdown.css'

const GET_PROJECTS_LIST = gql`
  subscription GetProjectsList {
    projects {
      id
      name
    }
  }
`

const ProjectDropDown = () => {
  const { projectId } = useParams()
  const { loading, error, data } = useSubscription(GET_PROJECTS_LIST)
  const navigate = useNavigate()

  const clickProject = useCallback((event) => navigate(`/project/${event.target.value}`), [navigate])

  if (error) return null
  if (loading) return <span>Loading...</span>

  const dropDownOptions = data?.projects?.map(({ id, name }) => <option key={id} value={id}>{name}</option>)

  return (
    <label className="project-dropdown__label" htmlFor="project-dropdown">
      Project:
      <select className="project-dropdown" value={projectId} onChange={clickProject} id="project-dropdown">
        {dropDownOptions}
      </select>
    </label>
  )
}

export default ProjectDropDown
