import React, { useCallback } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'

import './start-header.css'

const LoginButton = () => {
  const navigate = useNavigate()
  const clickLogin = useCallback(() => navigate('/login'), [navigate])

  return (
    <button
      data-testid="login-route"
      type="button"
      className="big-button login-button"
      onClick={clickLogin}
    >
      Log in
    </button>
  )
}

const StartHeader = () => {
  const location = useLocation()

  const onStartPage = location.pathname === '/'

  return (
    <header id="header" className="start-header">
      <Link className="title-link" to="/">Test Hut</Link>
      {onStartPage && <LoginButton />}
    </header>
  )
}

export default StartHeader
