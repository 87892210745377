import React from 'react'

import ProjectFiles from '../projectFiles/ProjectFiles'
import ProjectEvents from '../projectEvents/ProjectEvents'

const ProjectRunPage = ({ projectRuns }) => {
  if (!projectRuns.length) {
    return <span>No runs yet...</span>
  }

  return (
    <>
      <ProjectEvents />
      <ProjectFiles />
    </>
  )
}

export default ProjectRunPage
