import React from 'react'
import { useNavigate } from 'react-router-dom'
import { gql, useSubscription } from '@apollo/client'

import ProjectListItem from './ProjectListItem'

const GET_PROJECTS = gql`
  subscription GetProjectsList {
    projects {
      id
      name
      repo_url
      description
      updated_at
      created_at
      latestRun: project_runs_aggregate {
        aggregate {
          max {
            created_at
          }
        }
      }
      unresolvedThreads: project_comment_threads_aggregate(where: {resolved: {_eq: false}}) {
        aggregate {
          count
        }
      }
      testCases: project_file_tests_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`

const ProjectsList = () => {
  const navigate = useNavigate()
  const { loading, error, data } = useSubscription(GET_PROJECTS)

  if (!loading && !error && !data?.projects?.length) {
    return navigate('/new')
  }

  return (
    <div data-testid="project-list">
      {loading && <span>Loading...</span>}
      {error && (
      <span>
        Error listing projects...
        {error.message}
      </span>
      )}
      {!loading && (
      <ul className="project-list__list">
        {data?.projects.map((project) => (
          <ProjectListItem
            key={project.id}
            project={project}
          />
        ))}
      </ul>
      )}
    </div>
  )
}

export default ProjectsList
