import React, { memo } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Folder, File } from 'react-feather'
import classnames from 'classnames'

import buildFileTree from './buildFileTree'

import './file-list.css'

const FileListItem = ({ path, name, children }) => {
  const location = useLocation()

  const fileQuery = new URLSearchParams(location.search).get('file')

  const isSelected = fileQuery === path

  const fileClasses = classnames('file-list-item', isSelected && 'file-list-item--selected')

  return (
    <li className={fileClasses}>
      <File size="20" className="file-list-item__icon" />
      <Link
        className="file-list-item__name"
        to={{
          search: `?file=${path}`,
        }}
      >
        {name}
      </Link>
      {children}
    </li>
  )
}

const ImagesPill = ({ images }) => {
  if (!images) return null

  const text = images === 1 ? '1 image' : `${images} images`

  return <span className="colored-pill colored-pill--green">{text}</span>
}

const TestsPill = ({ tests }) => {
  if (!tests) return null

  const text = tests === 1 ? '1 test' : `${tests} tests`

  return <span className="colored-pill colored-pill--blue">{text}</span>
}

const renderLevel = (fileTree) => {
  if (!fileTree.type) return fileTree.map(renderLevel)

  if (fileTree.type === 'file') {
    return (
      <FileListItem key={fileTree.path} path={fileTree.path} name={fileTree.name}>
        <div className="colored-pill__container">
          <ImagesPill images={fileTree.images} />
          <TestsPill tests={fileTree.tests} />
        </div>
      </FileListItem>
    )
  }

  return (
    <div key={fileTree.name}>
      <li className="file-list-item">
        <Folder size="20" className="file-list-item__icon" />
        <span className="file-list-item__name">{fileTree.name}</span>
      </li>
      <ul className="project-file-list__ul">
        {fileTree.children.map(renderLevel)}
      </ul>
    </div>
  )
}

const ProjectFileList = ({ files = [] }) => {
  const fileTree = buildFileTree(files)

  return (
    <div className="project-file-list">
      <ul className="project-file-list__top-ul">
        {renderLevel(fileTree)}
      </ul>
    </div>
  )
}

export default memo(ProjectFileList)
