import React from 'react'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom'
import Cookies from 'js-cookie'

import MainApp from './MainApp'
import ProjectListView from '../projectsList/ProjectListView'
import AddProjectPage from '../addProject/AddProjectPage'
import Start from '../start/Start'
import StartHeader from '../start/StartHeader'
import LoginForm from '../login/LoginForm'
import RegisterForm from '../login/RegisterForm'
import ForgotPassword from '../login/ForgotPassword'
import SetPassword from '../login/SetPassword'
import Invite from '../login/Invite'
import ProjectPage from '../project/ProjectPage'
import InvitePage from '../invite/InvitePage'
import ProjectPagePublic from '../project/ProjectPagePublic'

const AuthenticatedView = ({ publicView, unauthenticated, authenticated }) => {
  const hasRefreshToken = Cookies.get('refreshToken')

  if (!hasRefreshToken && publicView) {
    return <MainApp>{publicView}</MainApp>
  }

  if (!hasRefreshToken) {
    return unauthenticated
  }

  return <MainApp>{authenticated}</MainApp>
}

const LoginPage = ({ children }) => (
  <>
    <StartHeader />
    {children}
  </>
)

const App = () => (
  <Router>
    <Routes>
      <Route path="/login" element={<LoginPage><LoginForm /></LoginPage>} />
      <Route path="/register" element={<LoginPage><RegisterForm /></LoginPage>} />
      <Route path="/forgot-password" element={<LoginPage><ForgotPassword /></LoginPage>} />
      <Route path="/set-password" element={<LoginPage><SetPassword /></LoginPage>} />
      <Route path="/invite" element={<LoginPage><Invite /></LoginPage>} />
      <Route
        exact
        path="/"
        element={(
          <AuthenticatedView
            unauthenticated={<Start />}
            authenticated={<ProjectListView />}
          />
        )}
      />
      <Route
        exact
        path="/new"
        element={(
          <AuthenticatedView
            unauthenticated={<Navigate to="/" replace />}
            authenticated={<AddProjectPage />}
          />
        )}
      />
      <Route
        exact
        path="/invite-user"
        element={(
          <AuthenticatedView
            unauthenticated={<Navigate to="/" replace />}
            authenticated={<InvitePage />}
          />
        )}
      />
      <Route
        path="/project/:projectId/*"
        element={(
          <AuthenticatedView
            publicView={<ProjectPagePublic />}
            authenticated={<ProjectPage />}
          />
        )}
      />
      <Route
        path="*"
        element={<Navigate to="/" replace />}
      />
    </Routes>
  </Router>
)

export default App
