import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { ApolloProvider, ApolloClient, InMemoryCache } from '@apollo/client'

import StartHeader from './StartHeader'
import UpdateEmailForm from './UpdateEmailForm'
import Footer from '../footer/Footer'

import './start.css'

const apolloClient = new ApolloClient({
  uri: '/v1/graphql',
  cache: new InMemoryCache(),
})

const isInDev = window.location.protocol === 'http:'

const RegisterButton = () => {
  const navigate = useNavigate()
  const clickRegister = useCallback(() => navigate('/register'), [navigate])

  return (
    <button
      data-testid="signup-route"
      type="button"
      className="big-button register-button"
      onClick={clickRegister}
    >
      Sign up
    </button>
  )
}

const LiveDemoButton = () => (
  <a
    className="start-live-demo big-button"
    type="button"
    href="https://test-hut.tobiaslindstrom.se/project/a646b871-558b-446d-9535-f2b8bc3d337f"
    target="blank"
  >
    Live Demo of Test Hut
  </a>
)

const Start = () => (
  <ApolloProvider client={apolloClient}>
    <StartHeader />
    <section id="start" className="start-section">
      <h1 className="start-h1">
        Keep track of your product through your tests.
      </h1>
      <p className="start-p">
        Test Hut is a way for your team to collaborate and iterate on your product with
        the tests as the starting point. Annotate the tests with text and images and add comments.
      </p>
      <div className="start-buttons">
        {isInDev && <RegisterButton />}
        <LiveDemoButton />
      </div>
      <UpdateEmailForm />
    </section>
    <Footer />
  </ApolloProvider>
)

export default Start
