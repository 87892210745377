import { createClient, CloseCode } from 'graphql-ws'
import Cookies from 'js-cookie'
import decodeJWT from 'jwt-decode'

import refreshToken from './refreshToken'

let shouldRefreshToken = false
let tokenExpiryTimeout = null

const url = window.location.hostname === 'localhost' ? 'localhost:8080' : window.location.host
const protocol = window.location.protocol === 'http:' ? 'ws' : 'wss'

const getToken = () => Cookies.get('jwt')

const getCurrentTokenExpiresIn = () => {
  const token = Cookies.get('jwt')

  const { exp } = decodeJWT(token)

  return exp * 1000 - new Date().getTime()
}

const client = createClient({
  url: `${protocol}://${url}/v1/graphql`,
  shouldRetry: () => true,
  connectionParams: async () => {
    if (shouldRefreshToken || !getToken()) {
      await refreshToken()

      shouldRefreshToken = false
    }

    if (getCurrentTokenExpiresIn() < 0) {
      await refreshToken()
    }

    const token = getToken()

    return {
      headers: {
        authorization: token ? `Bearer ${token}` : '',
      },
    }
  },
  on: {
    connected: (socket) => {
      clearTimeout(tokenExpiryTimeout)

      tokenExpiryTimeout = setTimeout(() => {
        if (socket.readyState === WebSocket.OPEN) {
          socket.close(CloseCode.Forbidden, 'Forbidden')
        }
      }, getCurrentTokenExpiresIn())
    },
    closed: (event) => {
      if (event.code === CloseCode.Forbidden || event.code === CloseCode.BadRequest) {
        shouldRefreshToken = true
      }
    },
  },
})

export default client
