import axios from 'axios'
import Cookies from 'js-cookie'

import setCookies from './setCookies'

const REFRESH_URL = '/api/auth/refresh'

const refreshToken = async () => {
  const refresh = Cookies.get('refreshToken')

  if (!refresh) return null

  try {
    const { data } = await axios.post(REFRESH_URL, { refresh })

    setCookies(data)

    return data
  } catch (error) {
    Cookies.remove('jwt')
    Cookies.remove('refreshToken')

    window.location.href = '/login'

    return null
  }
}

export default refreshToken
