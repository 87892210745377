import { TokenRefreshLink } from 'apollo-link-token-refresh'
import { identity, noop } from 'lodash'

import isTokenValid from './isTokenValid'
import refreshToken from './refreshToken'

// Not really using the module as intended. It uses a strange multi step request process.
const tokenRefreshLink = new TokenRefreshLink({
  accessTokenField: 'jwt_token',
  isTokenValidOrUndefined: isTokenValid,
  fetchAccessToken: refreshToken,
  handleFetch: noop,
  handleResponse: () => identity,
  // eslint-disable-next-line no-console
  handleError: console.error,
})

export default tokenRefreshLink
