import React from 'react'

import './footer.css'

const Footer = () => (
  <footer className="footer">
    <span>© 2022 Test Hut</span>
  </footer>
)
export default Footer
