import React, {
  useState, useEffect, useCallback, useRef,
} from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie'
import { gql, useQuery } from '@apollo/client'
import { User, ChevronDown, ChevronUp } from 'react-feather'
import { noop } from 'lodash'

import { handleLogout } from '../utils/apolloClient'

import './user-menu.css'

const GET_USER_INFO_QUERY = gql`
  query GetUserInfo {
    me {
      name
      user_metadata {
        company {
          name
        }
      }
    }
  }
`

const UserMenu = () => {
  const node = useRef()
  const buttonNode = useRef()
  const navigate = useNavigate()

  const [showMenu, setShowMenu] = useState(false)
  const { loading, error, data } = useQuery(GET_USER_INFO_QUERY)

  const handleShowMenu = useCallback(() => {
    setShowMenu(!showMenu)
  }, [showMenu])

  const clickOutside = (event) => {
    if (node.current.contains(event.target) || buttonNode.current.contains(event.target)) {
      return
    }

    setShowMenu(false)
  }

  useEffect(() => {
    if (!showMenu) return noop

    document.addEventListener('mousedown', clickOutside)

    return () => document.removeEventListener('mousedown', clickOutside)
  }, [showMenu])

  const userName = data?.me[0]?.name
  const companyName = data?.me[0]?.user_metadata[0]?.company?.name

  useEffect(() => {
    if (data && !userName) {
      Cookies.remove('jwt')
      Cookies.remove('refreshToken')

      navigate('/login')
    }
  }, [navigate, userName, data])

  const refreshToken = Cookies.get('refreshToken')

  if (!refreshToken) return null

  if (loading) {
    return <span>Loading...</span>
  }

  if (error) {
    return (
      <span>
        Error:
        {error.message}
      </span>
    )
  }
  return (
    <>
      <button
        type="button"
        ref={buttonNode}
        onClick={handleShowMenu}
        className="big-button"
        data-testid="user-menu-button"
      >
        <User />
        {showMenu ? <ChevronUp /> : <ChevronDown />}
      </button>
      <div ref={node} className="user-menu" hidden={!showMenu}>
        <nav className="user-menu__nav">
          <div className="user-menu__item">
            <p>
              Signed in as&nbsp;
              <strong>{userName}</strong>
            </p>
            <p>
              Company:&nbsp;
              <i>{companyName}</i>
            </p>
          </div>
          <div className="user-menu__divider" />
          <div className="user-menu__item user-menu--clickable">
            <Link
              to="/invite-user"
              data-testid="invite-user-link"
              className="user-menu__invite"
            >
              Invite user
            </Link>
          </div>
          <div className="user-menu__divider" />
          <div className="user-menu__item user-menu--clickable">
            <button type="button" onClick={handleLogout} className="user-menu__logout" data-testid="logout-button">
              Sign out
            </button>
          </div>
        </nav>
      </div>
    </>
  )
}

export default UserMenu
