import React, { useCallback } from 'react'
import { useLocation } from 'react-router-dom'
import { HelpCircle } from 'react-feather'
import { sortBy } from 'lodash'

import FileView from './FileView'
import Image from '../components/Image'

import './selected-file-view.css'

const TestName = ({ name, codeLine }) => {
  // Can't use react-router-dom to set the hash. Prism won't pick it up.
  const handleClick = useCallback(() => {
    window.location.hash = `file-view.${codeLine}`
  }, [codeLine])

  return (
    <li>
      <button className="selected-file-link" type="button" onClick={handleClick}>{name}</button>
    </li>
  )
}

const TestNamesAndImages = ({ names, images }) => {
  const imagesAndNames = sortBy([...names, ...images], 'codeLine')

  return (
    <ul className="selected-file-list">
      {imagesAndNames.map(({ id, name, codeLine, src }) => (src ? <Image key={id} src={src} />
        : <TestName key={id} name={name} codeLine={codeLine} src={src} />))}
    </ul>
  )
}

const SelectedFileView = ({ files = [] }) => {
  const location = useLocation()

  const fileQuery = new URLSearchParams(location.search).get('file')

  const selectedFile = files?.find((file) => file?.path === fileQuery)

  if (!selectedFile) {
    return (
      <div className="project-file-selected project-file-no-selected">
        <HelpCircle />
        <p>Select a file to view the code, test cases and uploaded images.</p>
      </div>
    )
  }

  return (
    <div className="project-file-selected">
      <h4>Test cases and images</h4>
      <TestNamesAndImages names={selectedFile?.tests} images={selectedFile?.images} />
      <FileView code={selectedFile?.code} />
      <div />
    </div>
  )
}

export default SelectedFileView
