import React from 'react'
import ago from 's-ago'

const ReadableUpdatedAt = ({ text, updatedAt }) => {
  const readableUpdatedAt = ago(new Date(updatedAt))
  const updatedText = `${text} ${readableUpdatedAt}`

  return <span className="muted-span">{updatedText}</span>
}

export default ReadableUpdatedAt
