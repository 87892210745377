import {
  ApolloClient,
  InMemoryCache,
  split,
  createHttpLink,
  from,
} from '@apollo/client'
import { getMainDefinition } from '@apollo/client/utilities'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import Cookies from 'js-cookie'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'

import makeTokenRefreshLink from './makeTokenRefreshLink'
import graphQLWsClient from './graphQLWsClient'

const getToken = () => Cookies.get('jwt')

const deleteTokens = () => {
  Cookies.remove('jwt')
  Cookies.remove('refreshToken')
}

// TODO: Make sure that client.clearStore is run.
// TODO: Force all tabs to logout.
// See: https://hasura.io/blog/best-practices-of-using-jwt-with-graphql/#basics-logout
export const handleLogout = () => {
  deleteTokens()

  window.location.href = '/login'
}

const logoutLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    const foundJwtError = graphQLErrors.find(({ extensions }) => extensions?.code === 'invalid-jwt')

    if (foundJwtError) return handleLogout()
  }

  if (networkError?.statusCode === 401 || networkError?.extensions?.code === 'invalid-jwt') {
    return handleLogout()
  }

  return null
})

const httpLink = createHttpLink({
  uri: '/v1/graphql',
})

const wsLink = new GraphQLWsLink(graphQLWsClient)

const isSubsciptionQuery = ({ query }) => {
  const { kind, operation } = getMainDefinition(query)

  return kind === 'OperationDefinition' && operation === 'subscription'
}

const splitLink = split(isSubsciptionQuery, wsLink, httpLink)

const authLink = setContext((req, { headers, ...context }) => {
  const token = getToken()
  const authorization = token && { authorization: `Bearer ${token}` }

  return {
    headers: {
      ...headers,
      ...authorization,
    },
    ...context,
  }
})

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: from([
    makeTokenRefreshLink,
    logoutLink,
    authLink,
    splitLink,
  ]),
})

export default client
