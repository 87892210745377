import React from 'react'

import getReadableDate from '../utils/getReadableDate'

import './project-runs.css'

const ProjectRunItem = ({
  createdAt, nodeVersion, runStarted, runEnded, reporterVersion, jestVersion,
}) => {
  const durationInS = (new Date(runEnded) - new Date(runStarted)) / 60
  const durationInSRounded = Math.round(durationInS)
  const projectRunTime = getReadableDate(createdAt)

  return (
    <details className="run-details">
      <summary>
        Run at&nbsp;
        {projectRunTime}
      </summary>
      <ul className="run-details__list">
        <li>
          <span>
            Node version:&nbsp;
            {nodeVersion}
          </span>
        </li>
        <li>
          <span>
            Reporter version:&nbsp;
            {reporterVersion}
          </span>
        </li>
        <li>
          <span>
            Jest version:&nbsp;
            {jestVersion}
          </span>
        </li>
        <li>
          <span>
            Duration in seconds:&nbsp;
            {durationInSRounded}
          </span>
        </li>
      </ul>
    </details>
  )
}

const ProjectRunPicker = ({ projectRuns = [] }) => (
  <ul>
    {projectRuns.map((projectRun) => (
      <ProjectRunItem
        key={projectRun.id}
        createdAt={projectRun.created_at}
        nodeVersion={projectRun.node_version}
        runStarted={projectRun.run_started}
        runEnded={projectRun.run_ended}
        reporterVersion={projectRun.reporter_version}
        jestVersion={projectRun.jest_version}
      />
    ))}
  </ul>
)

export default ProjectRunPicker
