import React from 'react'
import { Link } from 'react-router-dom'
import { ApolloProvider } from '@apollo/client'

import UserMenu from '../userMenu/UserMenu'
import Footer from '../footer/Footer'
import apolloClient from '../utils/apolloClient'

import './main-app.css'

const MainApp = ({ children }) => (
  <ApolloProvider client={apolloClient}>
    <div className="main-container">
      <header className="main-header">
        <Link to="/" className="title-link">Test Hut</Link>
        <UserMenu />
      </header>
      <main className="main-main">
        {children}
      </main>
      <Footer />
    </div>
  </ApolloProvider>
)

export default MainApp
