import React, { useState, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { gql, useMutation } from '@apollo/client'

const UPDATE_PROJECT = gql`
  mutation UpdateProject($id: uuid!, $name: String!, $repoUrl: String!, $description: String!) {
    update_projects_by_pk(
      pk_columns: {
        id: $id
      },
      _set: {
        name: $name
        repo_url: $repoUrl
        description: $description
      }
    )
    {
      id
      name
      repo_url
      description
      is_public
    }
  }
`

const ProjectSettingsForm = ({ currentName, currentRepoUrl, currentDescription }) => {
  const { projectId } = useParams()
  const [updateProject] = useMutation(UPDATE_PROJECT)

  const [name, setName] = useState(currentName || '')
  const [repoUrl, setLink] = useState(currentRepoUrl || '')
  const [description, setDescription] = useState(currentDescription || '')

  const handleNameChange = useCallback(({ target }) => setName(target.value), [])
  const handleLinkChange = useCallback(({ target }) => setLink(target.value), [])
  const handleDescriptionChange = useCallback(({ target }) => setDescription(target.value), [])

  const onUpdateProject = useCallback(async (event) => {
    event.preventDefault()

    await updateProject({
      variables: {
        id: projectId,
        name,
        repoUrl,
        description,
      },
    })
  }, [updateProject, projectId, name, repoUrl, description])

  return (
    <form onSubmit={onUpdateProject}>
      <label htmlFor="update-name">
        Name:
        <input type="text" id="update-name" value={name} onChange={handleNameChange} />
      </label>
      <br />
      <label htmlFor="update-repo-url">
        Repo url:
        <input type="text" id="update-repo-url" value={repoUrl} onChange={handleLinkChange} />
      </label>
      <br />
      <label htmlFor="update-description">
        Description:
        <input type="text" id="update-description" value={description} onChange={handleDescriptionChange} />
      </label>
      <br />
      <button type="submit" className="big-button">Save</button>
    </form>
  )
}

export default ProjectSettingsForm
