import React from 'react'
import { useParams } from 'react-router-dom'
import { gql, useQuery } from '@apollo/client'

import SelectedFileView from './SelectedFileView'
import ProjectFileList from './ProjectFileList'

const GET_PROJECT_TEST_DATA = gql`
  query GetProjectTestData($projectId: uuid!) {
    project: projects_by_pk(id: $projectId) {
      id
      files: project_files(order_by: { path: asc }) {
        id
        path
        code
        tests: project_file_tests {
          id
          name
          codeLine: line_id
        }
        images: project_images {
          id
          src
          codeLine: line_id
        }
      }
    }
  }
`

const ProjectFiles = () => {
  const { projectId } = useParams()
  const {
    loading, error, data,
  } = useQuery(GET_PROJECT_TEST_DATA, { variables: { projectId } })

  return (
    <section id="project-files" data-testid="project-files">
      <h3>Files</h3>
      <div className="project-run-view">
        {loading && <span>Loading...</span>}
        {error && (
        <span>
          Error listing project files...
          {error.message}
        </span>
        )}
        {!loading && (
        <>
          <ProjectFileList files={data?.project?.files} />
          <SelectedFileView files={data?.project?.files} />
        </>
        )}
      </div>
    </section>
  )
}

export default ProjectFiles
