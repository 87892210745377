import React, { useState, useCallback } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { Check, Clipboard } from 'react-feather'

import './api-key-copy.css'

const ProjectApiKeyCopy = ({ apiKey }) => {
  const [copied, setCopied] = useState(false)

  const handleCopy = useCallback(() => setCopied(true), [])

  return (
    <div className="copy-field">
      <input readOnly size="32" data-testid="project-api-key" value={apiKey} className="copy-field__input" />
      <CopyToClipboard text={apiKey} onCopy={handleCopy}>
        <button
          type="button"
          className="copy-field__button"
        >
          {copied ? <Check /> : <Clipboard />}
        </button>
      </CopyToClipboard>
    </div>
  )
}

export default ProjectApiKeyCopy
