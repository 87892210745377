import React, { useState, useCallback } from 'react'
import { gql, useMutation, useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'

import ReadableUpdatedAt from '../components/ReadableUpdatedAt'

import './comments.css'

const ADD_COMMENT = gql`
  mutation AddCommentToProject($projectId: uuid!, $comment: String!) {
    insert_project_comments_one(object: {
      text: $comment,
      project_comment_thread: {data: {
        project_id: $projectId
      }}
    }) {
      id
    }
}
`

const GET_COMMENTS = gql`
  query GetProjectComments($projectId: uuid!) {
    project_comments(
      where: {project_comment_thread: {project_id: {_eq: $projectId}}},
      order_by: {created_at: desc}
    ) {
      id
      created_at
      text
      user {
        id
        name
      }
    }
  }
`

const Comment = ({ text, createdAt, userName }) => {
  const createdText = (
    <span>
      <b>{userName}</b>
      &nbsp;
      <ReadableUpdatedAt text="commented" updatedAt={createdAt} />
    </span>
  )

  return (
    <li>
      <div>
        {createdText}
        <br />
        <pre>{text}</pre>
      </div>
    </li>
  )
}

const ProjectComments = () => {
  const { projectId } = useParams()

  const {
    loading, error, data, refetch,
  } = useQuery(GET_COMMENTS, { variables: { projectId } })
  const [addComment] = useMutation(ADD_COMMENT)
  const [text, setText] = useState('')

  const handleTextChange = useCallback((event) => {
    setText(event.target.value)
  }, [])

  const handleAddComment = useCallback(async (event) => {
    event.preventDefault()

    await addComment({ variables: { projectId, comment: text } })

    setText('')

    return refetch()
  }, [addComment, projectId, text, refetch])

  if (loading) {
    return <span>Loading...</span>
  }

  if (error) {
    return (
      <span>
        Error:
        {error.message}
      </span>
    )
  }

  const projectComments = data.project_comments

  return (
    <div>
      <h3>Add comment</h3>
      <form onSubmit={handleAddComment} className="comment-form">
        <textarea
          id="comment-text"
          rows="7"
          value={text}
          onChange={handleTextChange}
          className="comment-area"
        />
        <button
          disabled={!text.length}
          type="submit"
          className="big-button"
        >
          Add comment
        </button>
      </form>
      <h3>Comments</h3>
      <ul>
        {projectComments.map((comment) => (
          <Comment
            key={comment.id}
            text={comment.text}
            userName={comment.user.name}
            createdAt={comment.created_at}
          />
        ))}
      </ul>

    </div>
  )
}

export default ProjectComments
