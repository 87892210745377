import React from 'react'

import ProjectApiKeyCopy from '../components/ProjectApiKeyCopy'

const NoProjectRuns = ({ apiKey }) => (
  <div className="content-box">
    <h3>No runs found for the project.</h3>
    <p>
      Set the API-key value to the environment variable
      <i>TEST_HUT_KEY</i>
      .
    </p>
    <h3>API-key:</h3>
    <ProjectApiKeyCopy apiKey={apiKey} />
  </div>
)

export default NoProjectRuns
