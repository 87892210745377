import React, { useCallback } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { gql, useMutation } from '@apollo/client'
import { Trash2 } from 'react-feather'

const DELETE_PROJECT = gql`
  mutation DeleteProject($id: uuid!) {
    delete_projects_by_pk(id: $id) {
      id
    }
  }
`

const DeleteProject = () => {
  const [deleteProject] = useMutation(DELETE_PROJECT)
  const { projectId } = useParams()
  const navigate = useNavigate()

  const handleDeleteClick = useCallback(async () => {
    await deleteProject({ variables: { id: projectId } })

    return navigate('/')
  }, [deleteProject, navigate, projectId])

  return (
    <button type="button" className="logout-button" onClick={handleDeleteClick}>
      <Trash2 />
      <span>Delete project</span>
    </button>
  )
}

export default DeleteProject
