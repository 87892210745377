import Cookies from 'js-cookie'
import decodeJWT from 'jwt-decode'

const isTokenValid = async () => {
  const token = Cookies.get('jwt')

  if (!token) return false

  const { exp } = decodeJWT(token)

  const isValid = exp * 1000 >= new Date().getTime()

  return isValid
}

export default isTokenValid
