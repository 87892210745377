import React, { useState, useEffect, useCallback } from 'react'
import axios from 'axios'
import { useSearchParams, useNavigate } from 'react-router-dom'

const SET_PASSWORD_URL = '/api/auth/password'

const SetPassword = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [password, setPassword] = useState('')
  const [errorMessage, setErrorMessage] = useState(null)

  const handlePasswordChange = useCallback(({ target }) => setPassword(target.value), [])

  const ticket = searchParams.get('ticket')

  const authenticateUser = useCallback((event) => {
    event.preventDefault()

    const setNewPassword = async () => {
      try {
        await axios.post(SET_PASSWORD_URL, {
          ticket,
          password,
        })

        navigate('/')
      } catch (error) {
        const statusCode = error?.response?.status

        setErrorMessage(statusCode === 400 ? 'Bad request' : 'Invalid password')
      }
    }

    setNewPassword()
  }, [navigate, ticket, password])

  useEffect(() => {
    if (!ticket) navigate('/')
  }, [navigate, ticket])

  return (
    <section className="login-container">
      <form onSubmit={authenticateUser}>
        <label htmlFor="password" className="content-box">
          Password
          <input
            data-testid="login-password"
            id="password"
            className="input-field"
            type="password"
            placeholder="Password"
            value={password}
            onChange={handlePasswordChange}
          />
        </label>
        <button data-testid="login-button" type="submit" className="big-button login-button">Set new password</button>
      </form>
      {errorMessage && <span>{errorMessage}</span>}
    </section>
  )
}

export default SetPassword
