import React, { useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { gql, useMutation } from '@apollo/client'
import { AlertTriangle } from 'react-feather'

import './toggle-public.css'

const UPDATE_PROJECT_PUBLIC = gql`
  mutation UpdateProject($id: uuid!, $public: Boolean!) {
    update_projects_by_pk(
      pk_columns: {
        id: $id
      },
      _set: {
        is_public: $public
      }
    )
    {
      id
      name
      repo_url
      description
      is_public
    }
  }
`

const ProjectPublicToggle = ({ isPublic }) => {
  const { projectId } = useParams()
  const [updatePublic] = useMutation(UPDATE_PROJECT_PUBLIC)

  const onUpdatePublic = useCallback(async () => {
    await updatePublic({
      variables: {
        id: projectId,
        public: !isPublic,
      },
    })
  }, [updatePublic, projectId, isPublic])

  return (
    <div>
      <div className="warning-box">
        <AlertTriangle className="warning-icon" />
        <p>
          <strong>Warning: </strong>
          Enabling this setting will make all test cases for the project public.
        </p>
      </div>
      <label htmlFor="public">
        Is public:
        <input
          data-testid="public-project-toggle"
          type="checkbox"
          id="public"
          checked={isPublic}
          onChange={onUpdatePublic}
        />
      </label>
    </div>
  )
}

export default ProjectPublicToggle
