import React from 'react'
import {
  useNavigate, useParams, Routes, Route, Link,
} from 'react-router-dom'
import { gql, useQuery } from '@apollo/client'
import { AlertTriangle } from 'react-feather'

import ProjectRunPage from './ProjectRunPage'
import ProjectRunPicker from './ProjectRunPicker'
import { PublicNavigation } from '../Navigation/Navigation'

import './project-public.css'

const GET_PROJECT = gql`
  query GetProjectFiles($projectId: uuid!) {
    projects_by_pk(id: $projectId) {
      id
      name
      project_runs(order_by: {created_at: desc}) {
        id
        created_at
        run_started
        run_ended
        node_version
        jest_version
        reporter_version
      }
    }
  }
`

const Main = () => {
  const { projectId } = useParams()
  const navigate = useNavigate()
  const { loading, error, data } = useQuery(GET_PROJECT, {
    variables: { projectId },
  })

  if (!loading && !error && !data?.projects_by_pk) {
    return navigate('/')
  }

  return (
    <>
      {loading && <span>Loading...</span>}
      {error && (
      <span>
        Error listing projects:
        {error.message}
      </span>
      )}
      {!loading && !error && <ProjectRunPage projectRuns={data?.projects_by_pk?.project_runs} />}
    </>
  )
}

const ProjectRuns = () => {
  const { projectId } = useParams()
  const { loading, error, data } = useQuery(GET_PROJECT, {
    variables: { projectId },
  })

  const projectRuns = data?.projects_by_pk?.project_runs

  if (error) return <span>{error.message}</span>

  if (!projectRuns?.length) {
    return <span>No runs yet...</span>
  }

  return (
    <>
      {loading && <span>Loading...</span>}
      <h2>Project runs:</h2>
      <ProjectRunPicker projectRuns={projectRuns} />
    </>
  )
}

const ProjectPagePublic = () => (
  <div>
    <div className="public-project-notify">
      <p>
        <AlertTriangle />
        <br />
        This project is publicly available to showcase its features.
        <br />
        Click&nbsp;
        <Link to="/">here</Link>
        &nbsp;to learn more about Test Hut and how your team could use it to keep track of your project&apos;s features.
      </p>
    </div>
    <br />
    <PublicNavigation />
    <br />
    <Routes>
      <Route path="/" element={<Main />} />
      <Route path="/runs" element={<ProjectRuns />} />
    </Routes>
  </div>
)

export default ProjectPagePublic
