import React, { useState, useCallback } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'

import setCookies from '../utils/setCookies'

const REGISTER_URL = '/api/auth/register'

const RegisterForm = () => {
  const navigate = useNavigate()
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [company, setCompany] = useState('')
  const [errorMessage, setErrorMessage] = useState(null)

  const handleNameChange = useCallback(({ target }) => setName(target.value), [])
  const handleEmailChange = useCallback(({ target }) => setEmail(target.value), [])
  const handlePasswordChange = useCallback(({ target }) => setPassword(target.value), [])
  const handleCompanyChange = useCallback(({ target }) => setCompany(target.value), [])

  const registerUser = useCallback((event) => {
    event.preventDefault()

    const register = async () => {
      try {
        const { data } = await axios.post(REGISTER_URL, {
          name,
          email,
          company,
          password,
        })

        setCookies(data)

        navigate('/')
      } catch (error) {
        const statusCode = error?.response?.status

        setErrorMessage(statusCode === 400 ? 'Bad request' : 'Wrong password')
      }
    }

    register()
  }, [navigate, name, email, company, password])

  return (
    <section className="login-container">
      <form onSubmit={registerUser}>
        <label htmlFor="name" className="content-box">
          Name
          <input
            id="name"
            data-testid="register-user-name"
            className="input-field"
            placeholder="Name"
            value={name}
            onChange={handleNameChange}
          />
        </label>
        <label htmlFor="register-email" className="content-box">
          Email
          <input
            id="register-email"
            data-testid="register-user-email"
            className="input-field"
            placeholder="Email"
            value={email}
            onChange={handleEmailChange}
          />
        </label>
        <label htmlFor="company" className="content-box">
          Company
          <input
            id="company"
            data-testid="register-user-company"
            className="input-field"
            placeholder="Company"
            value={company}
            onChange={handleCompanyChange}
          />
        </label>
        <label htmlFor="register-password" className="content-box">
          Password
          <input
            id="register-password"
            data-testid="register-user-password"
            className="input-field"
            type="password"
            placeholder="Password"
            value={password}
            onChange={handlePasswordChange}
          />
        </label>
        <button type="submit" data-testid="register-user-submit" className="big-button login-button">Sign up</button>
      </form>
      {errorMessage && <span>{errorMessage}</span>}
    </section>
  )
}

export default RegisterForm
