import React, { useState, useCallback } from 'react'
import { gql, useQuery } from '@apollo/client'

import SearchBarResults from './SearchBarResults'

import './search-area.css'

const SEARCH_QUERY = gql`
  query SearchAllProjects($search: String!) {
    projects: search_projects(args: {search: $search}) {
      id
      name
    }
    files: search_project_files(args: {search: $search}) {
      id
      projectId: project_id
      path
    }
    tests: search_project_file_tests(args: {search: $search}) {
      id
      projectId: project_id
      name
      file: project_file {
        path
      }
    }
    comments: search_project_comments(args: {search: $search}) {
      id
      thread: project_comment_thread {
        id
        projectId: project_id
      }
      text
    }
  }
`

const SearchBar = () => {
  const [searchValue, setSearchValue] = useState('')
  const [focused, setFocused] = useState(false)

  const handleInputChange = useCallback(({ target }) => setSearchValue(target.value), [])
  const handleOnFocus = useCallback(() => setFocused(true), [])
  const handleOnBlur = useCallback(() => setTimeout(() => setFocused(false), 50), [])

  const { data, error } = useQuery(SEARCH_QUERY, {
    variables: { search: searchValue },
    skip: searchValue.length < 3,
  })

  return (
    <div className="search-area">
      <input
        id="search-project"
        type="search"
        autoComplete="off"
        value={searchValue}
        onInput={handleInputChange}
        placeholder="Search"
        className="input-field"
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
      />
      {error && <div hidden={!focused} className="search-area__results"><li>Search error.</li></div>}
      {data && <div hidden={!focused} className="search-area__results"><SearchBarResults data={data} /></div>}
    </div>
  )
}

export default SearchBar
