import React, { useCallback, useState } from 'react'
import { gql, useMutation } from '@apollo/client'
import useLocalStorage from 'use-local-storage'

import './start.css'

export const ADD_UPDATE_EMAIL = gql`
  mutation InsertUpdateEmail($email: String) {
    insert_update_emails(objects: { email: $email }) {
      __typename
    }
  }
`

const UpdateEmailForm = () => {
  const [sentEmail, setSentEmail] = useLocalStorage('update-email-sent')
  const [addUpdateEmail] = useMutation(ADD_UPDATE_EMAIL)

  const [email, setEmail] = useState('')
  const handleEmailChange = useCallback(({ target }) => setEmail(target.value), [])

  const handleCreateProject = useCallback(async (event) => {
    event.preventDefault()

    if (!email.includes('@')) {
      return
    }

    await addUpdateEmail({ variables: { email } })

    setEmail('')
    setSentEmail(true)
  }, [addUpdateEmail, email, setSentEmail])

  if (sentEmail) {
    return (
      <span data-testid="add-update-email-sent">Your email have been added to be sent product updates.</span>
    )
  }

  return (
    <form onSubmit={handleCreateProject} className="start-email-form">
      <input
        type="email"
        placeholder="Your email"
        maxLength="100"
        value={email}
        onChange={handleEmailChange}
        className="input-field"
        data-testid="add-update-email"
      />
      <button
        type="submit"
        className="big-button login-button"
        data-testid="add-update-email-button"
      >
        Sign up for more updates
      </button>
    </form>
  )
}

export default UpdateEmailForm
