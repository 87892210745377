import React from 'react'
import {
  Link, useNavigate, useParams, Routes, Route,
} from 'react-router-dom'
import { gql, useSubscription } from '@apollo/client'

import ProjectRunPage from './ProjectRunPage'
import ProjectDropDown from './ProjectDropDown'
import NoProjectRuns from './NoProjectRuns'
import ProjectComments from './ProjectComments'
import { Navigation } from '../Navigation/Navigation'
import ProjectSettingsPage from '../projectSettings/ProjectSettingsPage'
import ProjectRunPicker from './ProjectRunPicker'

const GET_PROJECT = gql`
  subscription GetProjectFiles($projectId: uuid!) {
    projects_by_pk(id: $projectId) {
      id
      name
      api_key
      project_runs(order_by: {created_at: desc}) {
        id
        created_at
        run_started
        run_ended
        node_version
        jest_version
        reporter_version
      }
    }
  }
`

const Main = () => {
  const { projectId } = useParams()
  const navigate = useNavigate()
  const { loading, error, data } = useSubscription(GET_PROJECT, {
    variables: { projectId },
  })

  if (!loading && !error && !data?.projects_by_pk) {
    return navigate('/')
  }

  const apiKey = data?.projects_by_pk?.api_key
  const hasProjectRuns = data?.projects_by_pk?.project_runs.length

  return (
    <>
      {loading && <span>Loading...</span>}
      {error && (
      <span>
        Error listing projects:
        {error.message}
      </span>
      )}
      {!loading && !hasProjectRuns && <NoProjectRuns apiKey={apiKey} />}
      {!loading && !error && <ProjectRunPage projectRuns={data?.projects_by_pk?.project_runs} />}
    </>
  )
}

const ProjectRuns = () => {
  const { projectId } = useParams()
  const { loading, error, data } = useSubscription(GET_PROJECT, {
    variables: { projectId },
  })

  const projectRuns = data?.projects_by_pk?.project_runs

  if (error) return <span>{error.message}</span>

  if (!projectRuns?.length) {
    return <span>No runs yet...</span>
  }

  return (
    <>
      {loading && <span>Loading...</span>}
      <h2>Project runs:</h2>
      <ProjectRunPicker projectRuns={projectRuns} />
    </>
  )
}

const ProjectPage = () => (
  <div>
    <div className="top-navigation">
      <Link className="top-navigation__back-link" to="/">Back</Link>
      <ProjectDropDown />
    </div>
    <br />
    <Navigation />
    <br />
    <Routes>
      <Route path="/" element={<Main />} />
      <Route path="/comments" element={<ProjectComments />} />
      <Route path="/runs" element={<ProjectRuns />} />
      <Route path="/settings" element={<ProjectSettingsPage />} />
    </Routes>
  </div>
)

export default ProjectPage
