import React, { useCallback } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import AddProjectForm from './AddProjectForm'

const AddProjectPage = () => {
  const navigate = useNavigate()
  const redirect = useCallback(() => navigate('/'), [navigate])

  return (
    <>
      <Link to="/">Back</Link>
      <AddProjectForm refetch={redirect} />
    </>
  )
}

export default AddProjectPage
