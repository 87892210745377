import React, { useState, useCallback } from 'react'
import axios from 'axios'
import { useNavigate, Link } from 'react-router-dom'

import setCookies from '../utils/setCookies'

const LOGIN_URL = '/api/auth/login'

const LoginForm = () => {
  const navigate = useNavigate()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [errorMessage, setErrorMessage] = useState(null)

  const handleEmailChange = useCallback(({ target }) => setEmail(target.value), [])
  const handlePasswordChange = useCallback(({ target }) => setPassword(target.value), [])

  const authenticateUser = useCallback((event) => {
    event.preventDefault()

    const authenticate = async () => {
      try {
        const { data } = await axios.post(LOGIN_URL, {
          email,
          password,
        })

        setCookies(data)

        navigate('/')
      } catch (error) {
        const statusCode = error?.response?.status

        setErrorMessage(statusCode === 400 ? 'Bad request' : 'Wrong password')
      }
    }

    authenticate()
  }, [navigate, email, password])

  return (
    <section className="login-container">
      <form onSubmit={authenticateUser} className="login-form">
        <label htmlFor="email" className="content-box">
          Email
          <input
            data-testid="login-email"
            id="email"
            className="input-field"
            placeholder="Email"
            value={email}
            onChange={handleEmailChange}
          />
        </label>
        <label htmlFor="password" className="content-box">
          Password
          <input
            data-testid="login-password"
            id="password"
            className="input-field"
            type="password"
            placeholder="Password"
            value={password}
            onChange={handlePasswordChange}
          />
        </label>
        <button data-testid="login-button" type="submit" className="big-button login-button">Log in</button>
      </form>
      {errorMessage && <span>{errorMessage}</span>}
      <Link to="/forgot-password">Forgot your password?</Link>
    </section>
  )
}

export default LoginForm
