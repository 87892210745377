import React, { useState, useCallback } from 'react'
import axios from 'axios'
import Cookies from 'js-cookie'
import decodeJWT from 'jwt-decode'

const INVITE_URL = '/api/email/invite-user'

const getOrgId = () => {
  const token = Cookies.get('jwt')
  const decoded = decodeJWT(token)

  return decoded['https://hasura.io/jwt/claims']['x-hasura-org-id']
}

const InviteForm = () => {
  const [email, setEmail] = useState('')
  const [sentInvite, setSentInvite] = useState(false)

  const handleEmailChange = useCallback(({ target }) => setEmail(target.value), [])

  const sendInvite = useCallback(async (event) => {
    event.preventDefault()

    if (!email) return

    const orgId = getOrgId()

    await axios.post(INVITE_URL, { email, orgId })

    setSentInvite(true)

    setTimeout(() => setSentInvite(false), 5000)
  }, [email])

  return (
    <div>
      <form onSubmit={sendInvite}>
        <label htmlFor="invite-email" className="content-box">
          Email
          <input
            id="invite-email"
            data-testid="invite-user-email"
            className="input-field"
            placeholder="Email"
            value={email}
            onChange={handleEmailChange}
            required
          />
        </label>
        <button type="submit" className="big-button" data-testid="invite-send-button">
          <span>Send invite</span>
        </button>
      </form>
      {sentInvite && <span>Sent invite</span>}
    </div>
  )
}

export default InviteForm
