import React from 'react'
import InviteForm from './InviteForm'

const InvitePage = () => (
  <div>
    <h3>Invite another user to your company:</h3>
    <InviteForm />
  </div>
)

export default InvitePage
