import { last, sortBy } from 'lodash'

const getPathParts = (file) => file.replace('./', '').split('/')

const buildFileTree = (files) => {
  if (!files.length) return []

  return files.reduce((acc, file) => {
    const parts = getPathParts(file.path)

    if (parts.length === 1) {
      acc.push({
        type: 'file',
        name: last(parts),
        path: file.path,
        tests: file.tests.length,
        images: file.images.length,
      })

      return acc
    }

    let currentFolder = acc

    parts.forEach((path) => {
      const isLastPart = path === last(parts)

      if (isLastPart) {
        currentFolder.children.push({
          type: 'file',
          name: last(parts),
          path: file.path,
          tests: file.tests.length,
          images: file.images.length,
        })

        return null
      }

      if (currentFolder.children) {
        const newFolder = {
          type: 'folder',
          name: path,
          children: [],
        }

        currentFolder.children.push(newFolder)
        currentFolder = newFolder

        return null
      }

      const foundFolder = currentFolder.find((elem) => elem.type === 'folder' && elem.name === path)

      if (!foundFolder) {
        const newFolder = {
          type: 'folder',
          name: path,
          children: [],
        }

        currentFolder.push(newFolder)

        currentFolder = newFolder
      } else {
        currentFolder = foundFolder
      }

      return null
    })

    currentFolder.children = sortBy(currentFolder.children, 'type')

    return acc
  }, [])
}

export default buildFileTree
