import React, { useState, useCallback } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'

const FORGOT_PASSWORD_URL = '/api/auth/reset-password'

const ForgotPassword = () => {
  const navigate = useNavigate()
  const [email, setEmail] = useState('')
  const [errorMessage, setErrorMessage] = useState(null)

  const handleEmailChange = useCallback(({ target }) => setEmail(target?.value), [])

  const authenticateUser = useCallback((event) => {
    event.preventDefault()

    const forgotPassword = async () => {
      try {
        await axios.post(FORGOT_PASSWORD_URL, { email })

        navigate('/login')
      } catch (error) {
        const statusCode = error?.response?.status

        setErrorMessage(statusCode === 400 ? 'Bad request' : 'Wrong email')
      }
    }

    forgotPassword()
  }, [navigate, email])

  return (
    <div className="login-container">
      <form onSubmit={authenticateUser}>
        <label htmlFor="email">
          Email
          <input id="email" className="input-field" placeholder="Email" value={email} onChange={handleEmailChange} />
        </label>
        <button type="submit" className="big-button login-button">Reset password</button>
      </form>
      {errorMessage && <span>{errorMessage}</span>}
    </div>
  )
}

export default ForgotPassword
