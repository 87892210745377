import React from 'react'
import { useParams } from 'react-router-dom'
import { gql, useQuery } from '@apollo/client'

import ProjectPublicToggle from './ProjectPublicToggle'
import ProjectSettingsForm from './ProjectSettingsForm'
import DeleteProject from './DeleteProject'
import ProjectApiKeyCopy from '../components/ProjectApiKeyCopy'

const GET_CURRENT_PROJECT = gql`
  query GetProjectData($projectId: uuid!) {
    projects_by_pk(id: $projectId) {
      id
      name
      repo_url
      description
      api_key
      is_public
    }
  }
`

const ProjectSettingsPage = () => {
  const { projectId } = useParams()
  const {
    loading, error, data,
  } = useQuery(GET_CURRENT_PROJECT, { variables: { projectId } })

  if (error) return <span>{error.message}</span>
  if (loading) return <span>Loading</span>

  return (
    <div>
      <h2>Project settings page</h2>
      <h3>API-key:</h3>
      <ProjectApiKeyCopy apiKey={data?.projects_by_pk?.api_key} />
      <h3>Edit:</h3>
      <ProjectSettingsForm
        currentName={data?.projects_by_pk?.name}
        currentRepoUrl={data?.projects_by_pk?.repo_url}
        currentDescription={data?.projects_by_pk?.description}
      />
      <h3>Make the project public:</h3>
      <ProjectPublicToggle isPublic={data?.projects_by_pk?.is_public} />
      <h3>Delete:</h3>
      <DeleteProject />
    </div>
  )
}

export default ProjectSettingsPage
