import React, { memo, useEffect } from 'react'
import Prism from 'prismjs'

import 'prismjs/themes/prism.css'
import 'prismjs/plugins/line-numbers/prism-line-numbers'
import 'prismjs/plugins/line-highlight/prism-line-highlight'
import 'prismjs/plugins/line-numbers/prism-line-numbers.css'
import 'prismjs/plugins/line-highlight/prism-line-highlight.css'

import './file-view.css'

const FileView = ({ code }) => {
  useEffect(() => Prism.highlightAll(), [code])

  return (
    <pre id="file-view" className="line-numbers">
      <code className="language-javascript">{code}</code>
    </pre>
  )
}

export default memo(FileView)
