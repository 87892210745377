import React from 'react'

import CustomLink from './CustomLink'

import './nav-bar.css'

export const Navigation = () => (
  <nav>
    <ul className="project-navbar">
      <CustomLink to="" str="Main" />
      <CustomLink to="comments" str="Comments" />
      <CustomLink to="runs" str="Runs" />
      <CustomLink to="settings" str="Settings" />
    </ul>
  </nav>
)

export const PublicNavigation = () => (
  <nav>
    <ul className="project-navbar">
      <CustomLink to="" str="Main" />
      <CustomLink to="runs" str="Runs" />
    </ul>
  </nav>
)
