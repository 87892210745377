import React, { useState, useCallback } from 'react'
import { gql, useMutation } from '@apollo/client'

export const CREATE_PROJECT = gql`
  mutation InsertProject($name: String, $repoUrl: String, $description: String) {
    insert_projects_one(object: { name: $name, repo_url: $repoUrl, description: $description }) {
      __typename
      id
    }
  }
`

const AddProjectForm = ({ refetch }) => {
  const [addProject] = useMutation(CREATE_PROJECT)

  const [name, setName] = useState('')
  const [repoUrl, setLink] = useState('')
  const [description, setDescription] = useState('')

  const handleNameChange = useCallback(({ target }) => setName(target.value), [])
  const handleLinkChange = useCallback(({ target }) => setLink(target.value), [])
  const handleDescriptionChange = useCallback(({ target }) => setDescription(target.value), [])

  const handleCreateProject = useCallback(async (event) => {
    event.preventDefault()

    await addProject({ variables: { name, description, repoUrl } })

    setName('')
    setLink('')
    setDescription('')

    return refetch()
  }, [addProject, name, description, repoUrl, refetch])

  return (
    <div className="content-box">
      <p data-testid="add-a-project-message">Add a project:</p>
      <form onSubmit={handleCreateProject} className="main-form">
        <label htmlFor="repo-name" className="content-box">
          Name:
          <input
            type="text"
            id="repo-name"
            value={name}
            onChange={handleNameChange}
            className="input-field"
            data-testid="add-project-name"
          />
        </label>
        <label htmlFor="repo-description" className="content-box">
          Description:
          <input
            type="text"
            id="repo-description"
            value={description}
            onChange={handleDescriptionChange}
            className="input-field"
            data-testid="add-project-description"
          />
        </label>
        <label htmlFor="repo-repo" className="content-box">
          Repository:
          <input
            type="text"
            id="repo-repo"
            value={repoUrl}
            onChange={handleLinkChange}
            className="input-field"
            data-testid="add-project-repo-url"
          />
        </label>
        <button
          type="submit"
          className="big-button login-button"
          data-testid="add-project-button"
        >
          Add project
        </button>
      </form>
    </div>
  )
}

export default AddProjectForm
