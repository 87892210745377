import React, { useState, useEffect, useCallback } from 'react'
import axios from 'axios'
import { useSearchParams, useNavigate } from 'react-router-dom'

import setCookies from '../utils/setCookies'

const REGISTER_INVITE_URL = '/api/auth/register-invite'

const Invite = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [errorMessage, setErrorMessage] = useState(null)

  const handleNameChange = useCallback(({ target }) => setName(target.value), [])
  const handleEmailChange = useCallback(({ target }) => setEmail(target.value), [])
  const handlePasswordChange = useCallback(({ target }) => setPassword(target.value), [])

  const id = searchParams.get('id')

  const registerUser = useCallback((event) => {
    event.preventDefault()

    const register = async () => {
      try {
        const { data } = await axios.post(REGISTER_INVITE_URL, {
          id,
          name,
          email,
          password,
        })

        setCookies(data)

        navigate('/')
      } catch (error) {
        const statusCode = error?.response?.status

        setErrorMessage(statusCode === 400 ? 'Bad request' : 'Invalid password')
      }
    }

    register()
  }, [navigate, id, name, email, password])

  useEffect(() => {
    if (!id) navigate('/')
  }, [navigate, id])

  return (
    <section className="login-container">
      <form onSubmit={registerUser}>
        <label htmlFor="name" className="content-box">
          Name
          <input
            id="name"
            data-testid="register-user-name"
            className="input-field"
            placeholder="Name"
            value={name}
            onChange={handleNameChange}
          />
        </label>
        <label htmlFor="register-email" className="content-box">
          Email
          <input
            id="register-email"
            data-testid="register-user-email"
            className="input-field"
            placeholder="Email"
            value={email}
            onChange={handleEmailChange}
          />
        </label>
        <label htmlFor="register-password" className="content-box">
          Password
          <input
            id="register-password"
            data-testid="register-user-password"
            className="input-field"
            type="password"
            placeholder="Password"
            value={password}
            onChange={handlePasswordChange}
          />
        </label>
        <button data-testid="register-invite-button" type="submit" className="big-button login-button">Register</button>
      </form>
      {errorMessage && <span>{errorMessage}</span>}
    </section>
  )
}

export default Invite
