import React from 'react'
import classnames from 'classnames'
import { Link, useResolvedPath, useMatch } from 'react-router-dom'

const CustomLink = ({ to, str }) => {
  const resolved = useResolvedPath(to)
  const match = useMatch({ path: resolved.pathname, end: true })

  const linkClasses = classnames('project-navbar__item', match && 'project-navbar__item--active')

  return (
    <li className={linkClasses} data-testid={`project-${to}`}>
      <Link to={to}>{str}</Link>
    </li>
  )
}

export default CustomLink
