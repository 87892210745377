import React from 'react'
import { Link } from 'react-router-dom'

import SearchBar from '../search/SearchBar'
import ProjectsList from './ProjectsList'

const ProjectListView = () => (
  <>
    <nav className="nav-bar">
      <SearchBar />
      <Link to="/new">
        <button type="button" className="big-button open-discussions--green">New project</button>
      </Link>
    </nav>
    <ProjectsList />
  </>
)

export default ProjectListView
