import React from 'react'
import { Check, AlertTriangle } from 'react-feather'

import './open-discussions.css'

const NoOpenDiscussions = () => (
  <span className="open-discussions open-discussions--green">
    <Check className="open-discussions__icon" />
    No open discussions
  </span>
)

const OpenDiscussions = ({ unresolvedThreads }) => {
  if (unresolvedThreads === 0) {
    return <NoOpenDiscussions />
  }

  const openDiscussionText = `${unresolvedThreads} open discussions`

  return (
    <span className="open-discussions open-discussions--yellow">
      <AlertTriangle className="open-discussions__icon" />
      {openDiscussionText}
    </span>
  )
}

export default OpenDiscussions
