import React, { useState, useCallback } from 'react'

import './image.css'

const PNG_PREFIX = 'data:image/png;base64,'

/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
const Image = ({ src, filePath }) => {
  const [showModal, setShowModal] = useState(false)

  const handleShowModal = useCallback(() => setShowModal((prev) => !prev), [])

  return (
    <>
      <button
        title="Click to show image in full size"
        type="button"
        onClick={handleShowModal}
        className="event-image__button"
      >
        <img
          className="event-image event-image__small"
          src={`${PNG_PREFIX}${src}`}
          alt={filePath}
        />
      </button>
      <div hidden={!showModal} className="event-image__modal" onClick={handleShowModal}>
        <img
          className="event-image"
          src={`${PNG_PREFIX}${src}`}
          alt={filePath}
        />
      </div>
    </>
  )
}

export default Image
