import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

const SearchBarResultRow = ({ url, text }) => {
  const navigate = useNavigate()
  const clickHandler = useCallback(() => navigate(url), [navigate, url])

  return (
    <button type="button" onMouseDown={clickHandler} className="big-button">
      <span>{text}</span>
    </button>
  )
}

const hasSearchResults = (data) => data.projects?.length
  || data.comments?.length
  || data.fileTests?.length
  || data.files?.length

const SearchBarResultProjects = ({ projects }) => (
  <>
    {projects?.length > 0 && <li><b>Projects:</b></li>}
    {projects?.map(({ id, name }) => (<SearchBarResultRow key={id} text={name} url={`/project/${id}`} />))}
  </>
)

const SearchBarResultTests = ({ tests }) => (
  <>
    {tests?.length > 0 && <li><b>Tests:</b></li>}
    {tests?.map(({ id, name, file, projectId }) => (
      <SearchBarResultRow key={id} text={name} url={`/project/${projectId}?file=${file.path}`} />
    ))}
  </>
)

const SearchBarResultFiles = ({ files }) => (
  <>
    {files?.length > 0 && <li><b>Files:</b></li>}
    {files?.map(({ id, path, projectId }) => (
      <SearchBarResultRow key={id} text={path} url={`/project/${projectId}?file=${path}`} />
    ))}
  </>
)

const SearchBarResultComments = ({ comments }) => (
  <>
    {comments?.length > 0 && <li><b>Comments:</b></li>}
    {comments?.map(({ id, text, thread }) => (
      <SearchBarResultRow key={id} text={text} url={`/project/${thread?.projectId}/comments`} />
    ))}
  </>
)

const SearchBarResults = ({ data }) => {
  if (!hasSearchResults(data)) {
    return <span>No search results found.</span>
  }

  return (
    <ul>
      <SearchBarResultProjects projects={data.projects} />
      <SearchBarResultTests tests={data.tests} />
      <SearchBarResultFiles files={data.files} />
      <SearchBarResultComments comments={data.comments} />
    </ul>
  )
}

export default SearchBarResults
